<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
    persistent
  >
    <v-card
      outlined
      color="#fff"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-file-document-edit
        </v-icon>Update Time Sheet
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text>
            <p class="my-p-style">
              Update Tracked Output Quantity <br><br>
              <span class="my-p-style-sub">NB:- This timesheet tracked output quantity is a dummy value, please update to the correct figure.</span>
            </p>
          </v-card-text>
          <v-row
            class="fill-height ml-2 mr-2"
          >
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="outputQuantity"
                class="field-style"
                label="Output Quantity*"
                hint="Material quantity used"
                :rules="[(v) => !!v || 'This field is required']"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              :disabled="timeSheetDetails.status === 'failed'"
              @click="completeValidations()"
            >
              Update
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <partner-auth-check
      ref="partnerAuth"
      @partner-authorized="updateTimeSheetDetails"
    />
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
  </v-dialog>
</template>

<script>
import Constants from 'src/constants';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import moment from 'moment';
import PartnerAuthCheck from 'src/views/dashboard/partnerauth/PartnerAuthCheck';
import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';

export default {
 name: 'EditTimeSheetDetails',
  components: {
    'centre-spinner': spinner,
    'partner-auth-check': PartnerAuthCheck,
    'overlay-alert': OverlayAlert,
  },
  props: {
    timeSheetDetails: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      showModal: true,
      isFormValid: false,
      loading: false,
      startDate: '',
      endDate: '',
      inputQuantity: null,
      outputQuantity: null,
      outputUnit: '',
      outputUnitsList: ['cf', 'ci', 'cy', 'ea', 'ft', 'ft-in', 'gal', 'in', 'lb', 'ls', 'mi', 'oz', 'pt', 'qt', 'sf', 'si', 'sy', 't(s)', 'yd'],
      showProgress: false,
      processAlert: '',
    };
  },
  computed: {
    trackingItemInstanceDetails () {
        return this.$store.getters['projects/getTrackingItemInstanceDetails'];
    },
    isInstanceFromPartner () {
      if (this.trackingItemInstanceDetails.database === false) {
        return true;
      }
      return false;
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  mounted () {
    this.loadOnMount();
  },
  methods: {
    loadOnMount () {
        this.loading = true;
        this.startDate = this.timeSheetDetails.start_date;
        this.endDate = this.timeSheetDetails.end_date;
        this.inputQuantity = this.timeSheetDetails.input_quantity;
        this.outputQuantity = this.timeSheetDetails.output_quantity;
        this.outputUnit = this.timeSheetDetails.output_unit;
        this.loading = false;
    },
    convertToGmt (date) {
      return moment(date).utc();
    },
    completeValidations () {
      if (this.$refs.form.validate() === false) {
        this.$store.dispatch('alert/onAlert', {
          message: 'Please fill the required fields before proceeding.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        return;
      }
      if (this.isInstanceFromPartner === true) {
        this.processAlert = 'Verifying...';
        this.showProgress = true;
        this.$refs.partnerAuth.isPartnerAuthorized();
        return;
      }
      this.updateTimeSheetDetails();
    },
    async updateTimeSheetDetails () {
      this.showProgress = false;
      this.loading = true;
      await this.$store.dispatch('projects/updateTimeSheetDetails', {
        updateDetails: {
          input_quantity: this.inputQuantity,
          input_unit: 'hr',
          output_quantity: this.outputQuantity,
          output_unit: this.outputUnit,
          start_date: this.startDate,
          end_date: this.endDate,
        },
        timeSheetId: this.timeSheetDetails.id,
      }).then(response => {
        this.$store.dispatch('alert/onAlert', {
        message: 'Instance time sheet updated successfully.',
        type: Constants.ALERT_TYPE_SUCCESS,
      });
        this.loading = false;
        this.$emit('success');
        this.showModal = false;
      }).catch(() => {
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
  margin-bottom: 10px;
}
.icon-style {
  color: #37474F;
  font-size: 55px;
  margin-right: 5px;
}
.field-style {
  margin-top: -10px;
}
.hrw-checkbox {
  margin-top: -30px;
}
.info-text {
  margin-left: 10px;
}
.nb-text {
  color: #37474F;
  font-style: italic;
  font-family: 'Times New Roman', Times, serif;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.my-p-style-sub {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
</style>
